
// import "../scss/style.scss";

import jQuery from 'jquery';
const $ = jQuery;

import ImgLazyLoad from './module/img-lazy-load';

window.jQuery = $;
window.$ = $;

import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';

import Swiper from 'swiper';

jQueryBridget('masonry', Masonry, $);

import 'cssuseragent';
import 'jquery.easing';
// import 'slick-carousel';

class Slideshow {
  constructor() {
    this.currentImage = 0;
    this.zIndex = 1;
    this.$items = [];
    $('.SlideshowItem').each((i, e) => {
      this.$items.push($(e));
    });
    this.loadedImage = 0;
    this.isLoadCompleteFirstImage = false;

    this.loadDataSize = 'data-large';

    if($('html').hasClass('ua-mobile')) {
      this.loadDataSize = 'data-small';
    }

    let src = this.$items[0].attr(this.loadDataSize);
    let image = new Image();

    image.onload = () => {
      console.log('Load complete - ' + this.loadedImage);
      this.$items[0].css('background-image', 'url(' + image.src + ')');
      this.$items[0].addClass('SlideshowItem--fadein');
      this.loadedImage++;
      // this.loadNext();
      this.isLoadCompleteFirstImage = true;

      this.interval = setInterval(() => {
        clearInterval(this.interval);
        this.next();
      }, 5800);
    }
    image.src = src;//this.$items[0].attr('data-original');


  }
  loadNext() {
    let src = this.$items[this.loadedImage].attr(this.loadDataSize);
    let image = new Image();

    image.onload = () => {
      console.log('Load complete - ' + this.loadedImage);
      this.$items[this.loadedImage].css('background-image', 'url(' + image.src + ')');
      this.loadedImage++;
      if(this.loadedImage != this.$items.length) {
        this.loadNext();
      }

    }
    image.src = src;//this.$items[0].attr('data-original');


  }
  next() {
    this.currentImage++;
    if(this.currentImage == this.$items.length) {
      this.currentImage = 0;
    }
    this.zIndex++;

    this.$items[this.currentImage].removeClass('SlideshowItem--fadein');
    setTimeout(() => {
      this.$items[this.currentImage].css('z-index', this.zIndex);
      this.$items[this.currentImage].addClass('SlideshowItem--fadein');

      this.interval = setInterval(() => {
        clearInterval(this.interval);
        this.next();
      }, 5800);
    }, 50);

  }
}

class MainMenu {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$btnToggleMenu = $('.BtnToggleMenu');
    this.$bg = $('.MenuContainerBg');
    this.isOpen = false;
    this.isProgress = false;

    this.$btns = [];
    $('.MenuListItem').each((i, e) => {
      this.$btns.push($(e));
    });

    $('.js-close-menu').on('click', () => {
      $('.BtnToggleMenu').removeClass(' BtnToggleMenu--close');
    });


    $('.BtnMainMenu').on('click', (e) => {
      e.stopPropagation();
      e.preventDefault();

      let category = $(e.currentTarget).attr('data-category');


      if($(e.currentTarget).attr('data-category') == 'profile') {
        this.mainApp.profile.open();
      } else {
        $(e.currentTarget).addClass('BtnMainMenu--current');
        $('.BtnMainMenu').each((i, e) => {
          if($(e).attr('data-category') != category && $(e).hasClass('BtnMainMenu--current')) {
            $(e).removeClass('BtnMainMenu--current');
          }
        });
        this.mainApp.selectItem(category);
        $('.BtnToggleMenu').removeClass(' BtnToggleMenu--close');
      }
      this.close();
    });

    $('.MenuContainer').on('click', (e) => {
      this.close();
    });

    this.$btnToggleMenu.on('click', () => {
      if(this.mainApp.profile.isOpen) {
        this.mainApp.profile.close();
        $('.BtnToggleMenu').removeClass(' BtnToggleMenu--close');
      } else {
        if(!this.isProgress) {
          if(this.isOpen) {
            $('.BtnToggleMenu').removeClass(' BtnToggleMenu--close');
            this.close();
          } else {
            this.open();
          }
        }
      }


    });
  }
  open() {
    this.isProgress = true;
    this.isOpen = true;
    $('.MenuContainer').css('display', 'block');
    $('.BtnToggleMenu').addClass(' BtnToggleMenu--close');
    // $('.')

    setTimeout(() => {
      this.$bg.addClass('MenuContainerBg--show');
      // $('.Header').addClass('Header--open-menu');

      for(let i = 0; i < this.$btns.length; i++) {
        this.$btns[i].delay(i * 70).queue(() => {
          this.$btns[i].addClass('MenuListItem--show').dequeue();
          if(i == this.$btns.length - 1) {
            this.isProgress = false;
          }
        });
      }
    }, 30);

  }
  close() {
    this.isProgress = true;
    this.isOpen = false;
    this.$bg.removeClass('MenuContainerBg--show');
    // $('.Header').removeClass('Header--open-menu');

    for(let i = 0; i < this.$btns.length; i++) {
      this.$btns[i].delay(i * 50).queue(() => {

        if(i == this.$btns.length - 1) {
          setTimeout(() => {
            $('.MenuContainer').css('display', 'none');
            this.isProgress = false;
          }, 600);

        }
        this.$btns[i].removeClass('MenuListItem--show').dequeue();
      });
    }
  }
}


class Profile {
  constructor() {
    this.$container = $('.ProfileContainer');
    this.isOpen = false;
  }
  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      $('body').css('overflow-y', 'hidden');
      this.$container
        .stop(false)
        .css({'opacity': 0, 'display': 'block'})
        .animate({'opacity':1}, 400, 'easeOutExpo');
    }
  }
  close() {
    $('.BtnToggleMenu').removeClass(' BtnToggleMenu--close');
    if(this.isOpen) {
      this.isOpen = false;
      $('body').css('overflow-y', 'scroll');
      this.$container
        .stop(false)
        .animate({'opacity':0}, 400, 'easeOutExpo', () => {
          this.$container.css('display', 'none');
        });
    }
  }
}

class ContentHeader {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$container = $('.ContentHeader');
    this.$inner = $('.Heading');
  }

  onWindowScroll(scrollTop) {
    /*
    console.log('scrollTop:' + scrollTop + ', offsetTop:' + this.$container.offset()['top']);
    if(scrollTop >= this.$container.offset()['top']) {
      this.$inner.addClass('Heading--fix');
    } else {
      this.$inner.removeClass('Heading--fix');
    }
    */
  }
}

class MainApp {
  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }
  constructor() {

    this.viewMode       = 'pc';


    this.$window        = $(window);
    this.slideshow = new Slideshow();

    this.profile = new Profile();
    this.menu = new MainMenu(this);

    this.contentHeader = new ContentHeader(this);

    this.imgLazyLoad    = new ImgLazyLoad(this);



    // this.imgLazyLoad.init();

    $('.js-btn-pagetop').on('click', () => {
      $('html, body').animate({'scrollTop': 0}, 1000, 'easeInOutExpo');
      return false;
    });

    if(!this.isTouchDevice()) {
      $('.BtnMainMenu').on('mouseover', (e) => {
        let slideWidth = $(e.currentTarget).find('.BtnMainMenu__ja').width() + 16;
        $(e.currentTarget).find('.BtnMainMenu__inner--main').stop(false).animate({'margin-right': slideWidth}, 300, 'easeOutExpo');
        $(e.currentTarget).find('.BtnMainMenu__inner--main').addClass('BtnMainMenu__inner--main-hover');
        $(e.currentTarget).find('.BtnMainMenuOverlay').addClass('BtnMainMenuOverlay--hover');
      });

      $('.BtnMainMenu').on('mouseout', (e) => {
        $(e.currentTarget).find('.BtnMainMenu__inner').stop(false).animate({'margin-right': 0}, 900, 'easeOutExpo');
        $(e.currentTarget).find('.BtnMainMenu__inner--main').removeClass('BtnMainMenu__inner--main-hover');
        $(e.currentTarget).find('.BtnMainMenuOverlay').removeClass('BtnMainMenuOverlay--hover');
      });
    }



    $(window).on("orientationchange", () => {
      this.onWindowResize();
    });



    $('.grid').masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true
    });

    $('.ContentsHeight').css('height', $('.grid').height());



    this.isInitializedSpiper = false;

    /*

    $(document).on("click", selector, function () {
    // 処理
});

    */


    $(document).on('click', '.grid-item', (e) => {
      let targetPage = parseInt($(e.currentTarget).attr('data-index'));

      if(this.isInitializedSpiper) {
        $('.wrap-slide')
          .stop(false)
          .css({'display':'block', 'opacity':0});
        this.swiper.update();
        this.swiper.slideTo(targetPage, 0);

        $('.wrap-slide')
          .animate({'opacity':1}, 300, 'easeInOutSine');

      } else {
        $('.wrap-slide')
          .stop(false)
          .css(
            {
              'opacity': 0,
              'display': 'block'
            }
          );

        this.swiper = new Swiper ('.swiper-container', {
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.BtnSlideArrow--next',
            prevEl: '.BtnSlideArrow--prev'
          },
          /*scrollbar: {
            el: '.swiper-scrollbar',
          },*/
        });

        this.isInitializedSpiper = true;
        this.swiper.slideTo(targetPage, 0);
        $('.wrap-slide').animate({'opacity': 1}, 300, 'easeInOutSine');
      }
    });

    $('.js-close-slide').on('click', () => {
      $('.wrap-slide').stop(false).animate({'opacity':0}, 300, 'easeInOutSine', () => {
        $('.wrap-slide').css('display', 'none');
      });
    });

    $('.js-close-profile').on('click', () => {
      this.profile.close();
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();


  }

  selectItem(slug) {
    $('.grid-item').each((i, e) => {
      $('.grid').masonry('remove', $(e));
    });

    $('.grid').masonry('layout');

    if(this.isInitializedSpiper) {
      this.isInitializedSpiper = false;
      // deleteする
      this.swiper.destroy();

    }
    $('.swiper-wrapper').empty();

    let addIndex = 0;
    $('.DataItem').each((i, e) => {
      if(slug == 'all') {

        let category = $(e).attr('data-category');
        let $img = String($(e).find('.DataItemThumb').html());
        $img = $img.replace( 'class=""', 'class="img-lazy"' );
        let html = `
          <div class="grid-item" data-index="${addIndex}" data-category="${category}">
          ${$img}
          </div>
          `.replace(/^\n|\s+$|^ {4}/gm, '');
        let $html = $(html);
        $('.grid').append($html).masonry( 'appended', $html );

        let full_src = $(e).find('.DataItemFull').attr('data-src');

        let html_slide = '';
        if($(e).find('.DataItemFullYoutube').length > 0) {
          let $full_elem = $(e).find('.DataItemFullYoutube');
          let id = $full_elem.attr('data-id');
          let html_caption = '';
          if($full_elem.find('.DataItemFullYoutube__caption')) {
            html_caption = '<div class="MovieWrap__credit">' + $full_elem.find('.DataItemFullYoutube__caption').html() + '</div>';
          }
          html_slide = `
<div class="swiper-slide">
    <div class="Slidecontent">
        <div class="Slidecontent__center">
            <div class="MovieWrap">
                <div class="EmbedContainer">
                    <div class="EmbedContainer__inner">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                ${html_caption}
            </div>
        </div>
    </div>
</div>
          `;
        } else {
          html_slide = `
<div class="swiper-slide">
    <div class="Slidecontent" style="background-image: url(${full_src});">
    </div>
</div>
`;
        }

        $('.swiper-wrapper').append(html_slide);


        addIndex++;
      } else {
        if ( $(e).attr('data-category').indexOf(slug) != -1) {

          let category = $(e).attr('data-category');
          let $img = String($(e).find('.DataItemThumb').html());
          $img = $img.replace( 'class=""', 'class="img-lazy"' );
          let html = `
            <div class="grid-item" data-index="${addIndex}" data-category="${category}">
            ${$img}
            </div>
            `.replace(/^\n|\s+$|^ {4}/gm, '');
          let $html = $(html);
          $('.grid').append($html).masonry( 'appended', $html );

          let full_src = $(e).find('.DataItemFull').attr('data-src');

          let html_slide = '';
          if($(e).find('.DataItemFullYoutube').length > 0) {
            let $full_elem = $(e).find('.DataItemFullYoutube');
            let id = $full_elem.attr('data-id');
            let html_caption = '';
            if($full_elem.find('.DataItemFullYoutube__caption')) {
              html_caption = '<div class="MovieWrap__credit">' + $full_elem.find('.DataItemFullYoutube__caption').html() + '</div>';
            }
            html_slide = `
  <div class="swiper-slide">
      <div class="Slidecontent">
          <div class="Slidecontent__center">
              <div class="MovieWrap">
                  <div class="EmbedContainer">
                      <div class="EmbedContainer__inner">
                          <iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                  </div>
                  ${html_caption}
              </div>
          </div>
      </div>
  </div>
            `;
          } else {
            html_slide = `
  <div class="swiper-slide">
      <div class="Slidecontent" style="background-image: url(${full_src});">
      </div>
  </div>
  `;
          }

          $('.swiper-wrapper').append(html_slide);

          addIndex++;
        }
      }

    });

    this.imgLazyLoad.reset();
    this.onWindowScroll();

    setTimeout(() => {
      $('.grid').masonry({
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
      });

      $('.ContentsHeight').css('height', $('.grid').height());

      let heightDiff = window.innerHeight - Math.floor($('.CommonWidth').height() + 100);
      if(heightDiff < 0) {
        $('.Footer').height(0);
      } else {
        $('.Footer').height(heightDiff);
      }

      let scrollableElement;
      let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
      if ('scrollingElement' in document) {
        scrollableElement = document.scrollingElement;
      } else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
        scrollableElement = document.documentElement;
      } else if (firefox && parseInt(firefox[1]) <= 47) {
        scrollableElement = document.documentElement;
      } else {
        scrollableElement = document.body;
      }

      $(scrollableElement).animate(
        {'scrollTop': $('.Content').offset()['top']},
        800,
        'easeInOutExpo'
      );

      // $('.Content').offset('top')

      /*$('.grid').masonry({
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
      });*/
    }, 100);

  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if(this.scrollTop != this.prevScrollTop) {
      if(this.scrollTop > this.prevScrollTop) {
        this.scrollDiff = 1;
      } else {
        this.scrollDiff = -1;
      }
    }

    this.contentHeader.onWindowScroll(this.scrollTop);

    // if(this.isOnLoad) {
      this.imgLazyLoad.update(this.scrollTop, this.windowHeight);
    // }

    this.prevScrollTop = this.scrollTop;

  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();


    // $('.Firstview').css('height', this.windowHeight - $('.Content').height());
    // $('.Firstview').css('height', this.windowHeight - 60);
    $('.Firstview').css('height', this.windowHeight);
    // $('.Wrapper').css('padding-top', this.windowHeight - 60);

    $('.ContentsHeight').css('height', $('.grid').height());


    let heightDiff = window.innerHeight - Math.floor($('.CommonWidth').height() + 100);
    if(heightDiff < 0) {
      $('.Footer').height(0);
    } else {
      $('.Footer').height(heightDiff);
    }

    if(this.swiper != undefined) {
      this.swiper.update();
    }

    $('.grid').masonry('layout');



    this.onWindowScroll();
  }



  onLoad() {
    this.loadWaitInterval = setInterval(() => {
      if(this.slideshow.isLoadCompleteFirstImage) {
        clearInterval(this.loadWaitInterval);
        $('.Content').css('opacity', 1);
        this.slideshow.loadNext();
      }
    }, 1000 / 30);
  }

}






var mainApp = undefined;

$('document').ready(() => {
  /*
  $.ajax({'type':'get','url': window.url_template + '/assets/images/symbol-defs.svg'}).done((data) => {
    let svg = $(data).find('svg');
    $('body').append(svg);
  });
  */

  mainApp = new MainApp();

});

$(window).on('load', () => {
  console.log('window.onload');
  let initializedInterval = setInterval(() => {
    if(mainApp != undefined) {
      clearInterval(initializedInterval);
      mainApp.onLoad();
    }
  }, 1000 / 30);

});


